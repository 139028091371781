@import 'styles/mixins';

.header {
    display: flex;
    height: 117px;
    width: 100%;
    user-select: none;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all .1s ease-in-out;
    z-index: 101;

    &__sticky {
        background: #1c1c1c;
        height: 60px;
    }
    
    .wrapper {
        justify-content: space-between;
    }

    &__loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__price {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin-top: 3px;
    }

    &__loading-label {
        font-size: 12px;
        color: #FFF;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__left {
        flex: 1;
        display: flex;
        align-items: center;
        max-width: 390px;

        .logo {
            img {
                padding-left: 25px;
                padding-right: 25px;
            }
            &__label {
                appearance: none;
                padding-left: 25px;
                border-left: 1px solid #FFFFFF;
                color: #CAD8FC!important;
                font-size: 30px;
            }
        }
    }

    &__center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 57px;
        flex-direction: column;
    }

    &__round-progress {
        text-align: left;
        font-size: 25px;
        color: #FFF;
        font-weight: bold;
        margin-top: 5px;
        width: 125px;

        &_label {
            text-align: center;
            font-size: 12px;
            color: #FFF;
            font-weight: bold;
            margin-right: 5px;
        }

        span {
            margin-left: 15px;
            width: 115px;
        }
    }

    &__right {
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__allowance {
        font-size: 18px;
        font-weight: bold;
        color: #7FFFD4;
        margin-top: 5px;
    }

    .menu {
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            li {
                cursor: pointer;
                user-select: none;
                margin-right: 50px;
            }
        }
        &__address {
            color: #7FFFD4;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden; 
            white-space: nowrap;
            width: 95px; 
        }
        &__button {
            font-size: 22px;
            color: #fff;
            &_active {
                color: #7FFFD4;
                font-weight: bold;
            }
            &_bold {
                font-weight: bold;
            }
        }
    }
}

@include mq('tablet') {
    .header {
        &__sticky {
            padding-top: 60px;
        }
        &__center {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #000;
            height: 60px;
        }

        &__allowance {
            font-size: 15px;
        }
    }
}

@include mq('tablet') {
    .header {
        padding-top: 60px;

        .menu {
            width: 100%;
            ul {
                justify-content: center;
                li {
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &__sticky {
            height: auto;
            .header__left {
                margin-top: -70px;
            }
        }
        .wrapper {
            flex-direction: column;
            align-items: center;
        }
        &__left {
            transform: scale(0.6);
            transition: all .15s ease-in-out;
        }

        &__right {
            transform: scale(0.8);
        }
    }
}
