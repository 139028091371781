$breakpoints: (
  'phone-xxs': 321px,
  'phone-small': 400px,
  'phone': 480px,
  'phone-wide': 576px,
  'tablet-xxs': 640px,
  'tablet-small': 768px,
  'tablet': 960px,
  'tablet-wide': 1025px,
  'desktop-small': 1100px,
  'desktop': 1280px,
  'desktop-wide': 1440px,
  'desktop-xs': 1700px,
  'desktop-xsl': 1900px,
  'desktop-xsll': 2100px,
  'desktop-xslll': 2100px,
  'desktop-xsllll': 2450px,
  'desktop-xxs': 2600px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
